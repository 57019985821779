import React from 'react';
import { NavLink } from 'react-router-dom';

import ApplicationLayoutBodyBottomBar from '../../../src/ApplicationLayoutBodyBottomBar';
import useEvent from '../../../src/useEvent';
import { isMobile } from '../../../src/DeviceUtils';
import { history } from '../setup/store';

export const BREAKPOINT_MOBILE = 480;

export const MOBILE_BOTTOM_BAR_HEIGHT = 80;

export const pages = [
    {
        id: 'start',
        name: 'Home',
        icon: 'rioglyph rioglyph-home',
    },
    {
        id: 'design',
        name: 'Design',
        icon: 'rioglyph rioglyph-star',
    },
    {
        id: 'components',
        name: 'Components',
        icon: 'rioglyph rioglyph-workshop',
    },
    {
        id: 'map',
        name: 'Map',
        icon: 'rioglyph rioglyph-map-marker',
    },
    {
        id: 'charts',
        name: 'Charts',
        icon: 'rioglyph rioglyph-stats',
    },
];

export const navItems = pages.map(page => ({
    key: page.id,
    route: <NavLink to={`/${page.id}`}>{page.name}</NavLink>,
}));

export const useOrientationChangeDelayed = callback => {
    const delayedCallback = () => {
        setTimeout(() => {
            callback();
        }, 200);
    };

    // To support Browsers with old orientationchange event like Safari on iOS
    useEvent('orientationchange', delayedCallback);

    // Correct way of using Screen
    useEvent('change', delayedCallback, false, screen.orientation);
};

export const isMobilePortrait = () => window.innerWidth <= BREAKPOINT_MOBILE;

export const getBottomNavigationForMobile = () => (isMobile() && isMobilePortrait() ? <BottomNavigation /> : null);

export const BottomNavigation = props => {
    const currentPage = window.location.hash.replace('#', '');

    const navigate = page => history.push('/' + page);

    return (
        <ApplicationLayoutBodyBottomBar className='shadow-smooth-to-top'>
            <div className='display-flex justify-content-center align-items-center width-100pct height-100pct'>
                {pages.map(page => {
                    const isActive = currentPage.startsWith(page.id);
                    return (
                        <div key={page.id} onClick={() => navigate(page.id)} className='min-width-20pct'>
                            <div className='display-flex flex-column align-items-center row-gap-5'>
                                <span
                                    className={`${page.icon} ${
                                        isActive ? 'text-color-primary text-size-h2' : 'text-color-dark text-size-h3'
                                    }`}
                                />
                                <div className={`${isActive ? 'text-color-primary' : 'text-color-dark'} text-size-12`}>
                                    {page.name}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </ApplicationLayoutBodyBottomBar>
    );
};
