// @ts-ignore-next-line importsNotUsedAsValues
import React, { forwardRef, type ForwardedRef, type PropsWithChildren } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames';
import noop from 'lodash/fp/noop';

export type SmoothScrollbarsProps = {
    slideIn?: boolean;
    largeTrack?: boolean;
    trackOffset?: boolean;
    className?: string;
    tagName?: string;
    autoHeight?: boolean;
    autoHeightMin?: number;
    autoHeightMax?: number;
    onScroll?: (event: React.UIEvent<any> | undefined) => void;
};

const SmoothScrollbars = forwardRef(
    (componentProps: PropsWithChildren<SmoothScrollbarsProps>, ref: ForwardedRef<any>) => {
        const {
            slideIn = false,
            largeTrack = false,
            trackOffset = false,
            className = '',
            onScroll = noop,
            tagName = 'div',
            autoHeight = false,
            autoHeightMin = 0,
            autoHeightMax = 200,
            children,
        } = componentProps;

        const wrapperClassNames = classNames(className, slideIn && 'scrollbar-fly-in', 'smooth-scrollbar-wrapper');

        const renderTrackVerticalNames = classNames(
            'scrollbar-track-vertical',
            largeTrack && 'scrollbar-track-size-large',
            trackOffset && 'scrollbar-track-offset'
        );

        const renderThumbVerticalNames = classNames(
            'scrollbar-thumb-vertical',
            largeTrack && 'scrollbar-thumb-size-large'
        );

        const renderTrackHorizontalNames = classNames(
            'scrollbar-track-horizontal',
            largeTrack && 'scrollbar-track-size-large',
            trackOffset && 'scrollbar-track-offset'
        );

        const renderThumbHorizontalNames = classNames(
            'scrollbar-thumb-horizontal',
            largeTrack && 'scrollbar-thumb-size-large'
        );

        return (
            <Scrollbars
                ref={ref}
                tagName={tagName}
                onScroll={onScroll}
                className={wrapperClassNames}
                hideTracksWhenNotNeeded
                autoHeight={autoHeight}
                autoHeightMin={autoHeightMin}
                autoHeightMax={autoHeightMax}
                renderView={props => {
                    return <div {...props} className='scrollbar-view' />;
                }}
                renderTrackVertical={props => {
                    return <div {...props} className={renderTrackVerticalNames} />;
                }}
                renderThumbVertical={props => {
                    return <div {...props} className={renderThumbVerticalNames} />;
                }}
                renderTrackHorizontal={props => {
                    return <div {...props} className={renderTrackHorizontalNames} />;
                }}
                renderThumbHorizontal={props => {
                    return <div {...props} className={renderThumbHorizontalNames} />;
                }}
            >
                {children}
            </Scrollbars>
        );
    }
);

export default SmoothScrollbars;
