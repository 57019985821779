import { createContext } from 'react';
import noop from 'lodash/noop';

import { STYLE_RIO_LIGHT } from './header/Themes';
import { isMobile, isMobileScreen } from '../../src/utils/deviceUtils';

type UikitContextType = {
    theme: string;
    showMenu: boolean;
    onMenuIconClicked: () => void;
    onMenuOutsideClicked: (event: MouseEvent | TouchEvent) => void;
};

export const UikitContext = createContext<UikitContextType>({
    theme: STYLE_RIO_LIGHT,
    showMenu: !(isMobile() || isMobileScreen()),
    onMenuIconClicked: noop,
    onMenuOutsideClicked: noop,
});
