import type { BarProps as RechartsBarProps } from 'recharts';

export type BarProps = Omit<RechartsBarProps, 'ref'> & {};

const Bar: React.FC<BarProps> = () => null;

Bar.defaultProps = {
    dataKey: 'value',
    unit: '',
    color: 'color-coldplay-fountain',
    isAnimationActive: true,
};

export default Bar;
